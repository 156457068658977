import Form from 'src/components/marketplace/TriagemFormSeller'
import Seo from 'src/components/seo/Seo'

const desc =
  'Quer se tornar um parceiro Decathlon? Cadastre-se e venha vender no marketplace da maior loja de artigos esportivos!'

function Page() {
  return (
    <>
      <Seo title="Triagem | Decathlon" description={desc} />
      <Form />
    </>
  )
}

export default Page
